import "./bootstrap";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";
import App from "./App.vue";
import router from "./router";
const app = createApp(App);
app.use(createPinia());
app.use(router);
flare.light('3weyDwPj4iEsb8rtcREpGeTdLJCf4aCP');
app.use(flareVue);
app.directive("debounce", vue3Debounce({ lock: true }));
app.mount("#app");
