import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        // Auth routes
        {
            path: "/auth",
            name: "auth",
            component: () => import("@/views/auth/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "auth.login",
                    component: () => import("@/views/auth/LoginView.vue"),
                },
                {
                    path: "reset",
                    name: "auth.reset",
                    component: () => import("@/views/auth/ResetView.vue"),
                },
                {
                    path: "reset/:id",
                    name: "auth.reset.newpassword",
                    component: () => import("@/views/auth/NewPasswordView.vue"),
                },
            ],
        },
        {
            path: "/docs",
            name: "docs",
            component: () => import("@/views/docs/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "docs.index",
                    component: () => import("@/views/docs/IndexView.vue"),
                },
            ],
        },
        // Main dashboard
        {
            path: "/",
            name: "main",
            component: () => import("@/views/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "man.index",
                    component: () => import("@/views/DashboardView.vue"),
                },
                {
                    path: "staff",
                    name: "main.staff",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "main.staff.index",
                            component: () => import("@/views/staff/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "main.staff.create",
                            component: () => import("@/views/staff/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "main.staff.edit",
                            component: () => import("@/views/staff/CreateView.vue"),
                        },
                    ],
                },
                {
                    path: "retailers",
                    name: "main.retailers",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "main.retailers.index",
                            component: () => import("@/views/retailers/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "main.retailers.create",
                            component: () => import("@/views/retailers/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "main.retailers.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "main.retailers.profile.index",
                                    component: () => import("@/views/retailers/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "main.retailers.profile.edit",
                                    component: () => import("@/views/retailers/CreateView.vue"),
                                }
                            ]
                        },
                    ],
                },
                {
                    path: "reports",
                    name: "main.reports",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "main.reports.index",
                            component: () => import("@/views/reports/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "main.reports.create",
                            component: () => import("@/views/reports/CreateView.vue"),
                        },
                    ],
                },
            ],
        },
    ],
});
export default router;
